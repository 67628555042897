<template>
    <b-card-code title="我的子站">
        <div class="custom-search">
            <!-- advance search input -->
            <b-row>
                <b-col md="4">
                    <b-form-group>
                        <label>搜尋:</label>
                        <b-form-input placeholder="Search" type="text" class="d-inline-block" @input="advanceSearch" />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group>
                        <label>使用類型:</label>
                        <b-form-input placeholder="Search" type="text" class="d-inline-block" @input="advanceSearch" />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group>
                        <label>子站狀態:</label>
                        <b-form-input placeholder="Search" type="text" class="d-inline-block" @input="advanceSearch" />
                    </b-form-group>
                </b-col>
            </b-row>
        </div>

        <!-- table -->
        <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
                enabled: true,
                externalQuery: searchTerm,
            }"
            :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :pagination-options="{
                enabled: true,
                perPage: pageLength,
            }"
            theme="my-theme"
            @on-row-click="onRowClick"
        >
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                    <b-avatar :src="props.row.avatar" class="mx-1" />
                    <span>{{ props.row.fullName }}</span>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Showing 1 to </span>
                        <b-form-select
                            v-model="pageLength"
                            :options="['3', '5', '10']"
                            class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value) => props.pageChanged({ currentPage: value })"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import websiteList from '@/views/sub-website/websiteList.json'

export default {
    components: {
        BCardCode,
        VueGoodTable,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
    },
    data() {
        return {
            pageLength: 5,
            dir: false,
            columns: [
                {
                    label: '子站名稱',
                    field: 'fullName',
                },
                {
                    label: '網域',
                    field: 'domain',
                },
                {
                    label: '創建時間',
                    field: 'startDate',
                },
                {
                    label: '使用類型',
                    field: 'useType',
                },
                {
                    label: '約期',
                    field: 'contractPeriod',
                },
                {
                    label: '狀態',
                    field: 'status',
                },
                {
                    label: '維護',
                    field: 'maintain',
                },
            ],
            rows: [],
            searchTerm: '',
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true

                return this.dir
            }

            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false

            return this.dir
        },
    },
    created() {
        this.rows = websiteList
        // this.$http.get('/good-table/advanced-search').then((res) => {
        //     this.rows = res.data
        // })
    },
    methods: {
        advanceSearch(val) {
            this.searchTerm = val
        },
        onRowClick(params) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: `Hello user! You have clicked on row ${params.row.id}`,
                    icon: 'UserIcon',
                    variant: 'success',
                },
            })
        },
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
